<template>
  <div class="view">
    <div class="c-content">  
        <van-image
          class="c-image"
          v-for="(item, index) in list" 
          :key="index"
          :src="item.image"
          @click="goTo(item.url)"
          fit="contain"
        />
    </div>
  </div>
</template>
<script>


export default {
  data() {
    return {
      title: '慈光出品',
      info: [],
      list: [
        {'image':'https://i.yyii.info/static/ciguang_v1/image/ciguang-app.png','url':'https://u.jingzong.org/u/a8'},
        {'image':'https://i.yyii.info/static/ciguang_v1/image/ciguang-gzh.png','url':'https://u.jingzong.org/u/cgwh'},
        {'image':'https://i.yyii.info/static/ciguang_v1/image/daoying-app.png','url':'http://m.jingzong.net/dy/18'},
        {'image':'https://i.yyii.info/static/ciguang_v1/image/xingyi-gzh.png','url':'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg4MDU3NjAwMA==&scene=124#wechat_redirect'}
      ],
      isShow: false,
      isWx: false,
      loading: true,

    }
  },
  methods: {
    //返回
    onBack(){
      this.$router.push({ path: `/i` })  
    },
    onLoad(){
      console.log('---');
    },
    goTo(url){         
      window.location.href = url
    }
  },
  beforeDestroy() {

  },
  mounted(){
  },
  created() {
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
    margin-bottom: 70px;  
}
.c-content{
  padding:5px 5px;
  text-align: center;
}
.c-image{
    padding:10px 25px;
    background-color: #fff;
}
</style>