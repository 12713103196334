import { render, staticRenderFns } from "./CiguangProduct.vue?vue&type=template&id=387a6514&scoped=true&"
import script from "./CiguangProduct.vue?vue&type=script&lang=js&"
export * from "./CiguangProduct.vue?vue&type=script&lang=js&"
import style0 from "./CiguangProduct.vue?vue&type=style&index=0&id=387a6514&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387a6514",
  null
  
)

export default component.exports